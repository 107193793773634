import React from "react"
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'

import Layout from '../components/Layout/Layout'

export default function Services({ data }) {
  return (
    <Layout title="Services">
      <div>
        <h1>{data.markdownRemark.frontmatter.header}</h1>
        {data.markdownRemark.frontmatter.description ? <ReactMarkdown source={data.markdownRemark.frontmatter.description} /> : null}
        {data.markdownRemark.frontmatter.services.map(service => (
          <>
            <h2 style={{ marginTop: '2rem' }}>{service.name}</h2>
            <ReactMarkdown source={service.description} />
          </>
        ))}
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`query ServicesQuery {
  markdownRemark(frontmatter: {title: {eq: "Services"}}) {
    frontmatter {
      header
      description
      services {
        name
        description
      }
    }
  }
}
`
